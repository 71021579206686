import {AsideForm} from '@/components/AsideForm';
import {LabeledGridItem} from '@/components/LabeledGridItem';
import {Toolbar} from '@/components/Toolbar';
import {dogStatusChoices, sexChoices, stateChoices} from '@/lib/choices';
import {getActiveSubscriptionLabel} from '@/utils/getActiveSubscriptionLabel';
import {Grid} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {styled} from '@mui/material/styles';
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    DateInput,
    Edit,
    FunctionField,
    ImageField,
    ImageFieldClasses,
    NumberInput,
    ReferenceField,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    required,
    useEditContext,
    useGetList,
} from 'react-admin';
import {useWatch} from 'react-hook-form';
import {useMemo} from 'react';
import {DogBreedData} from '@/types';

const ProfileImageField = styled(ImageField)({
    width: '100%',
    margin: 0,
    [`& .${ImageFieldClasses.image}`]: {
        margin: 0,
        width: '100%',
        height: 200,
        objectFit: 'contain',
        objectPosition: 'left',
    },
});

const GalleryImageField = styled(ImageField)({
    [`& .${ImageFieldClasses.list}`]: {
        padding: 0,
        gap: 10,
    },
    [`& .${ImageFieldClasses.list} li`]: {
        display: 'flex',
        position: 'relative',
    },
    [`& .${ImageFieldClasses.list} li:nth-of-type(n+4)`]: {
        display: 'none',
    },
    [`& .${ImageFieldClasses.list} li:nth-of-type(3)::after`]: {
        position: 'absolute',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontWeight: 700,
        backgroundColor: '#3339',
    },
    [`& .${ImageFieldClasses.image}`]: {
        margin: 0,
        width: 100,
        height: 100,
        objectFit: 'cover',
    },
});

const Title = () => {
    const {record} = useEditContext();
    if (record && 'name' in record) return `Dogs / ${record.name}`;
    return `Dogs`;
};

const GalleryImages = () => {
    const images = useWatch({name: 'images'});

    if (!images) return null;

    const overThreeAmount = images.length - 3;

    return (
        <GalleryImageField
            source="images"
            label="Gallery Images"
            src="url"
            sx={
                overThreeAmount > 0
                    ? {
                          [`& .${ImageFieldClasses.list} li:nth-child(3)::after`]:
                              {
                                  content: `'+${overThreeAmount}'`,
                              },
                      }
                    : undefined
            }
        />
    );
};

const UserDetails = () => {
    return (
        <Card variant="outlined" sx={{width: '100%', marginBottom: 3}}>
            <CardContent>
                <Grid container spacing={2}>
                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            label="User"
                            source="user_id"
                            reference="users"
                        />
                    </LabeledGridItem>

                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            source="user_id"
                            reference="users"
                            label="Registered Breeder"
                        >
                            <BooleanField source="is_registered_breeder" />
                        </ReferenceField>
                    </LabeledGridItem>

                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            source="user_id"
                            reference="users"
                            label="Suburb"
                        >
                            <TextField source="location.suburb" />
                        </ReferenceField>
                    </LabeledGridItem>

                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            source="user_id"
                            reference="users"
                            label="State"
                        >
                            <TextField source="location.state" />
                        </ReferenceField>
                    </LabeledGridItem>
                </Grid>
                <Grid container spacing={2}>
                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            label="Subscription"
                            source="user_id"
                            reference="users"
                        >
                            <FunctionField
                                render={(user) =>
                                    getActiveSubscriptionLabel(
                                        user.subscription
                                    )
                                }
                            />
                        </ReferenceField>
                    </LabeledGridItem>

                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            label="Male Dog Count"
                            source="user_id"
                            reference="users"
                        >
                            <TextField source="count_male_dogs" />
                        </ReferenceField>
                    </LabeledGridItem>

                    <LabeledGridItem xs={3}>
                        <ReferenceField
                            label="Female Dog Count"
                            source="user_id"
                            reference="users"
                        >
                            <TextField source="count_female_dogs" />
                        </ReferenceField>
                    </LabeledGridItem>
                </Grid>
            </CardContent>
        </Card>
    );
};

const BreedInputs = () => {
    const {data: breedsData} = useGetList<DogBreedData>('breeds', {});

    const choices = useMemo(
        () =>
            (breedsData || []).map((item) => ({id: item.id, name: item.label})),
        [breedsData]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AutocompleteInput
                    source="primary_breed_id"
                    validate={required()}
                    label="Choose Breed"
                    choices={choices}
                />
            </Grid>
        </Grid>
    );
};

const ArtificalInseminationInput = () => {
    const sex = useWatch({name: 'sex'});

    if (sex === 'female') return null;

    return <BooleanInput source="artificial_insemination" />;
};

export const DogEdit = () => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Edit title={<Title />} mutationMode="optimistic">
                    <AsideForm>
                        <TextField
                            source="name"
                            sx={{fontSize: 'h4.fontSize', marginBottom: 2}}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <SelectInput
                                    source="status"
                                    choices={dogStatusChoices}
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={4} alignContent={'center'}>
                                <SaveButton sx={{marginBottom: 2}} />
                            </Grid>
                        </Grid>

                        <ProfileImageField
                            source="profile_image.url"
                            label="Profile Image"
                        />
                        <GalleryImages />
                    </AsideForm>
                </Edit>
            </Grid>
            <Grid item xs>
                <Edit
                    title=" " // Prevent a second title from showing up
                    mutationMode="optimistic"
                >
                    <TabbedForm toolbar={<Toolbar />}>
                        <TabbedForm.Tab label="Registration">
                            <UserDetails />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        source="registration_state"
                                        choices={stateChoices}
                                        validate={required()}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="registration_number" />
                                </Grid>
                            </Grid>

                            <TextInput source="microchip_number" />
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Details">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        source="sex"
                                        choices={sexChoices}
                                        validate={required()}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        source="dob"
                                        label="Date of Birth"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput
                                        source="height"
                                        label="Height (cm)"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput
                                        source="weight"
                                        label="Height (kg)"
                                    />
                                </Grid>
                            </Grid>

                            <BooleanInput source="is_purebred" />

                            <BreedInputs />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <NumberInput source="litters_count" />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        source="health_check_date"
                                        label="Last Vet Visit"
                                    />
                                </Grid>
                            </Grid>
                            <BooleanInput source="can_travel" />
                            <ArtificalInseminationInput />
                        </TabbedForm.Tab>
                    </TabbedForm>
                </Edit>
            </Grid>
        </Grid>
    );
};
