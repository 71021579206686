import {getApi, resetApi} from '@/api/utils';
import {API_URL} from '@/utils/constants';
import {Log} from '@/utils/log';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {AuthProvider} from 'react-admin';
import {NavigateFunction} from 'react-router-dom';

export const getAuthProvider = (navigate: NavigateFunction): AuthProvider => ({
    // called when the user attempts to log in
    login: async ({email, password}) => {
        const url = `${API_URL}/user/login`;
        const response = await axios.post(url, {
            email,
            password,
        });

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }

        const {auth, user} = response.data.data;

        const decodedIdToken = jwtDecode(auth.id_token);

        const isAdminUser =
            'cognito:groups' in decodedIdToken &&
            Array.isArray(decodedIdToken['cognito:groups']) &&
            decodedIdToken['cognito:groups'].includes('administrators');

        if (isAdminUser) {
            localStorage.setItem(
                'auth',
                JSON.stringify({...auth, user_id: user.id})
            );

            return Promise.resolve();
        } else {
            return Promise.reject(new Error('Not an admin user'));
        }
    },

    checkError: async (error) => {
        const status = error.status;

        console.log('error.status: ', status);

        switch (status) {
            case 403:
                localStorage.removeItem('auth');

                try {
                    await resetApi();
                } catch (error) {
                    Log.error(error);
                }

                return Promise.reject();
            default:
                // other error code (404, 500, etc): no need to log out
                return Promise.resolve();
        }
    },

    checkAuth: async () => {
        const api = getApi(navigate);
        const result = await api.checkAuth();

        if (result) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    },

    logout: async () => {
        localStorage.removeItem('auth');

        try {
            await resetApi();
        } catch (error) {
            Log.error(error);
        }

        return Promise.resolve();
    },

    getPermissions: () => {
        return Promise.resolve();
    },
});
